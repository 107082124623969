import React from "react"
import { Link } from "gatsby"
import './services.css'

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageHero from '../components/pageHero'
import servicesHero from '../images/services-hero.png'
import safety from '../images/safety.png'
import shotGlass from '../images/icon-shot-glass.png'
import martiniGlass from '../images/icon-martini-glass.png'
import tallGlass from '../images/icon-tall-glass.png'

const servicesData = [
  {title: "Staff & Consumer Training", text: "We provide full-service training and beverage development services for venues around the world, including: pubs, clubs, fine dining, lounges and boutique cocktail bars.", link:"development-training", image: tallGlass},
  {title: "V.I.P. EVENTS & PARTIES", text: "What sets our events and parties apart from every other in town? Character – every element of the party, from the service to the beverages, must live and breathe your brand.", link:"vip-events-parties", image: shotGlass},
  {title: "Ambassadorship & Brand Representation", text: "This business is our passion – there is no place we feel more at home than behind a bar – that’s why clients work with us to represent their brand.", link:"ambassadorship-representation", image: martiniGlass},
  {title: "Workplace Culture, Harassment & Safety", text: "We help organizations step beyond traditional health and safety and do more to develop safe, inclusive and successful workplaces.", link:"workplace-culture-harassment-safety", image: safety}
]

const TeamPage = () => (
  <Layout>
    <SEO title="Libation Ltd Services" />
    <PageHero {...{title: "Services", image: servicesHero}}/>
    <div className="page-section services">
      <div className="services-wrapper">
        {servicesData.map(service => {
          return (<div className="service-single flex">
                    <div className="service-image" style={{backgroundImage: `url(${service.image})`}}></div>
                    <div className="flex-stacked">
                      <p className="service-title">{service.title}</p>
                      <p className="service-text">{service.text}</p>
                      <div className="service-page-link-anchor">
                        <a href={"services/" + service.link}>Learn More</a>
                      </div>
                    </div>
                </div>

          )
        })}
      </div>
    </div>
  </Layout>
)

export default TeamPage
